import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from '../../assets/logo.png'
import survey from '../../assets/survey.svg'
import './style.css';

class FirstParty extends Component {
  constructor(props) {
    super(props)
    this.queryString = window.location.href;

    if (this.queryString !== "" && this.queryString.includes("PID")) {
      
      this.IsSS = this.queryString.split('IsSS=')[1];
      this.pid = this.queryString.split('PID=')[1]; 
    }

    this.state = {
      pid: this.pid,
      status : '',
      IsSS : this.IsSS
    }   
    this.onChange = this.onChange.bind(this);
    this.callRedirect = this.callRedirect.bind(this);
  }

  componentWillMount() {
    
    if (!this.state.pid) {
     alert("You enter wrong url address !");
    }
  }

  onChange(e){
    this.setState({[e.target.name]: e.target.value});
    }

    callRedirect(e){
      e.preventDefault();
      if(this.state.status){
        if(this.state.status === "complete"){
          let completeUrl = this.IsSS == 1 ? 'https://complete.surveysherpa.com/?UID='+ this.state.pid : process.env.REACT_APP_COMPLETE_URL+ this.state.pid ;
         
          window.open(completeUrl, '_self');
        }else if(this.state.status === "overquota"){
          let overQuotaUrl = this.IsSS == 1 ? 'https://quotafail.surveysherpa.com/?UID='+ this.state.pid : process.env.REACT_APP_OVERQUOTA_URL+ this.state.pid ;
          
          window.open(overQuotaUrl, '_self');
        }else if(this.state.status === "terminate"){
          
          let terminateUrl = this.IsSS == 1 ? 'https://terminate.surveysherpa.com/?UID='+ this.state.pid : process.env.REACT_APP_TERMINATE_URL+ this.state.pid ;
          window.open(terminateUrl, '_self');
        }else if(this.state.status === "securityterminate"){
          
          let securityterminate = this.IsSS == 1 ? 'https://securityfail.surveysherpa.com/?UID='+ this.state.pid : process.env.REACT_APP_SECURITY_URL+ this.state.pid ;
          window.open(securityterminate, '_self');
        }
      }      
    }

  render() {
    

    return (
      <>
        <div>
          <img src={logo} alt="logo" className='logoImg' />
        </div>
        <div className='center-div'>
          <div className='img-border'>
            <img src={survey} alt="survey" className='survey'/>
          </div>
          <div className='div-width'>
            <div className="firstPartyHeader">
              <h2 className='heading'>Test your redirect configurations</h2>
            </div>
            <h2 className='message'>This survey is for testing purpose, we can not pay for this.</h2>
            <div  style={{ display: "flex", justifyContent: "center"}}>
            <div className="firstPartyForm">
              <form onSubmit={this.callRedirect}>
                <div className='input-border complete'>
                  <label style={{ display: "flex"}}>
                    <input
                      type="radio"
                      name="status"
                      value="complete"
                      onChange={this.onChange}
                      className='checkbox'
                    />
                    &nbsp;Complete
                  </label>
                </div>
                <div className='input-border overquota'>
                  <label style={{ display: "flex"}}>
                    <input
                      type="radio"
                      name="status"
                      value="overquota"
                      onChange={this.onChange}
                      className='checkbox'
                    />
                    &nbsp;Quota Full
                  </label>
                </div>
                <div className='input-border terminate'>
                  <label style={{ display: "flex"}}>
                    <input
                      type="radio"
                      name="status"
                      value="terminate"
                      onChange={this.onChange}
                      className='checkbox'
                    />
                    &nbsp;Termination
                  </label>
                </div>
                <div className='input-border securityterminate'>
                  <label style={{ display: "flex"}}>
                    <input
                      type="radio"
                      name="status"
                      value="securityterminate"
                      onChange={this.onChange}
                      className='checkbox'
                    />
                    &nbsp;Security Termination
                  </label>
                </div>
                <div className='btn-align'>
                  <button type="submit" className="firstPartyBtn">Test Now</button>
                </div>
              </form>
            </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
   
  }
}

export default connect(mapStateToProps)(FirstParty);
